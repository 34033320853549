import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Button,
  Typography,
  Box,
  TextField,
  IconButton,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  useTheme,
  alpha,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
  InputAdornment,
} from "@mui/material";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ReactPlayer from "react-player";
import { categories } from "../UploadVideo";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import {
  fetchVideosTags,
  updateVideo,
  fetchVideoAouth,
} from "../../APIS/videos";
import { toast } from "react-toastify";
import { motion, AnimatePresence } from "framer-motion";
import { useQuery } from "react-query";
import { getRole } from "../../layout/Sidebar";
import { getVideoStatus } from "../../APIS/roleAndPermission";
import CommentsSection from "./chatbox";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { Close } from "@mui/icons-material";
import { FFmpeg } from "@ffmpeg/ffmpeg";

const MotionBox = motion(Box);

export const VideoDetailModal = ({
  videoId,
  openModal,
  handleClose,
  refetch,
}) => {
  const [processing, setProcessing] = useState(false);

  const {
    data: fetchedCategories,
    categoriesLoading,
    error,
  } = useQuery("videoTags", fetchVideosTags);

  const {
    data: video,
    isLoading: isVideoLoading,
    error: videoError,
    refetch: refetchVideo,
  } = useQuery(["video", videoId], () => fetchVideoAouth(videoId), {
    enabled: !!videoId,
  });

  const {
    data: videoStatuses,
    videoStatusLoading,
    videoStatusError,
  } = useQuery("VideoStatus", getVideoStatus);
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(video?.name);
  const [editedTag, setEditedTag] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState();
  const [duration, setDuration] = useState(0);
  const [videoUrl, setVideoUrl] = useState(video?.videos3link);
  const [comment, setComment] = useState("");
  const [hadithText, setHadithText] = useState("");
  const [keyboardVisible, setKeyboardVisible] = useState(false);

  useEffect(() => {
    setEditedTitle(video?.name);
    setEditedTag(
      fetchedCategories?.find((cat) => cat.name === video?.category)
    );
    const latestStatus = getLatestVideoStatus(video);
    let statusMessage = 0;

    if (latestStatus) {

      if (
        latestStatus.statusDescription === "Approved" &&
        latestStatus?.user?.role === "Student" && role?.name =="Teacher"
      ) {
        statusMessage = 0;
      } else if (
        latestStatus.statusDescription === "Approved" &&
        latestStatus?.user?.role === "Teacher" 
      ) {
        statusMessage = 1;
      } else if (
        latestStatus.statusDescription === "Pending" &&
        latestStatus?.user?.name === "Student"
      ) {
        statusMessage = 0;
      } else if (
        latestStatus.statusDescription === "Approved" &&
        latestStatus?.user?.name === "Student" && !role?.name =="Teacher"
      ) {
        statusMessage = 1;
      } 
      // else if (latestStatus.statusDescription === "Rejected" &&  latestStatus?.user?.role === "Teacher") {
      //     statusMessage = "Rejected by Teacher";
      // } else if(latestStatus.statusDescription === "Rejected" && latestStatus?.user?.role?.name === "Student"){
      //     statusMessage = "Rejected by Student";
      // }else if(latestStatus.statusDescription === "Approved" &&  latestStatus?.user?.role === "SuperAdmin"){
      //   statusMessage = "Approved By Admin";
      // }
      else{
        statusMessage = latestStatus?.status;
      }
    }

    setSelectedStatus(statusMessage);
    setVideoUrl(video?.videos3link);
    setComment(video?.comment);
    setHadithText(video?.hadithText || "");
  }, [video]);

  const handleChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleEditClick = () => setIsEditing(true);

  const role = getRole();
  const isDataEntry = role?.name === "DataEntry";
  const isStudent = role?.name === "Student";

  const handleSave = async () => {
    try {
      const payload = {
        name: editedTitle,
        category: editedTag?.name,
        videoStatus: parseInt(selectedStatus),
        comment: comment,
        hadithText: hadithText,
      };

      const updateResponse = await updateVideo(payload, video?.id);

      if (updateResponse?.status === 200) {
        toast.success("Video details successfully updated");
      } else {
        toast.error("Failed to update video details");
      }

      refetch();
      refetchVideo();
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating video:", error);
      toast.error("An error occurred while updating the video");
    } finally {
      setIsEditing(false);
    }
  };

  const handleReady = () => setLoading(false);
  const handleBuffer = () => setLoading(true);
  const handleDuration = (duration) => setDuration(duration);

  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${hours > 0 ? hours + ":" : ""}${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const gradientBg =
    theme.palette.mode === "dark"
      ? "linear-gradient(135deg, #1a1a1a 0%, #2c2c2c 100%)"
      : "linear-gradient(135deg, #f0f0f0 0%, #ffffff 100%)";

  const buttonGradient = "linear-gradient(45deg, #006400 30%, #32CD32 90%)";
  const buttonHoverGradient =
    "linear-gradient(45deg, #005000 30%, #2AB52A 90%)";

  if (videoStatusLoading || isVideoLoading || categoriesLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const displayComments = (comments) => {
    return comments?.map((comment) => {
      const { firstname, role } = comment.user;
      return `${firstname}(${role.name}): ${comment.comment}`;
    });
  };

  const formattedComments = displayComments(video?.videoCommentsReponse);

  const handleKeyPress = (input) => {
    setHadithText((prev) => prev + input);
  };

  console.log(keyboardVisible, "keyboard");

  return (
    <Dialog
      open={openModal}
      onClose={() => {
        setKeyboardVisible(false);
        handleClose();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: {
          borderRadius: 20,
          background: gradientBg,
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography
          variant="h5"
          sx={{
            color: theme.palette.mode === "dark" ? "#FFD700" : "#006400",
            fontWeight: "bold",
            textShadow: "1px 1px 2px rgba(0,0,0,0.1)",
          }}
        >
          Video Details
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Box
            sx={{
              position: "relative",
              width: "100%",
              paddingTop: "56.25%", // 16:9 aspect ratio
              marginBottom: "20px",
              borderRadius: 4,
              overflow: "hidden",
              boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
            }}
          >
            {loading && (
              <CircularProgress
                size={60}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "#FFD700",
                }}
              />
            )}
            <ReactPlayer
              url={videoUrl}
              width="100%"
              height="100%"
              style={{ position: "absolute", top: 0, left: 0 }}
              controls={true}
              muted={false}
              onReady={handleReady}
              onBuffer={handleBuffer}
              onDuration={handleDuration}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <AccessTimeIcon
              sx={{
                color: theme.palette.mode === "dark" ? "#FFD700" : "#006400",
                mr: 1,
              }}
            />
            <Typography
              variant="body1"
              sx={{ color: theme.palette.mode === "dark" ? "#fff" : "#333" }}
            >
              Duration: {formatDuration(duration)}
            </Typography>
          </Box>
          <MotionBox
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            py={2}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: theme.palette.mode === "dark" ? "#fff" : "#006400",
                }}
              >
                {isEditing ? "Edit Video Details" : "Video Information"}
              </Typography>
              {!isEditing && !isDataEntry && (
                <IconButton
                  onClick={handleEditClick}
                  sx={{
                    color:
                      theme.palette.mode === "dark" ? "#FFD700" : "#006400",
                    "&:hover": {
                      backgroundColor: alpha(theme.palette.success.main, 0.1),
                    },
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
            </Box>
            <AnimatePresence mode="wait">
              {!isEditing ? (
                <MotionBox
                  key="details"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      mb: 1,
                      color: theme.palette.mode === "dark" ? "#fff" : "#333",
                    }}
                  >
                    <strong>Title:</strong> {video?.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      mb: 1,
                      color: theme.palette.mode === "dark" ? "#fff" : "#333",
                    }}
                  >
                    <strong>Tag:</strong> {video?.category || "Nimaz"}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      mb: 1,
                      color: theme.palette.mode === "dark" ? "#fff" : "#333",
                    }}
                  >
                    <strong>Uploaded By:</strong> {video?.uploadedby}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      mb: 1,
                      color: theme.palette.mode === "dark" ? "#fff" : "#333",
                    }}
                  >
                    <strong>Uploaded At:</strong>{" "}
                    {moment(video?.createdAt).format("MMM Do YY")}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                    <CheckCircleIcon
                      sx={{ color: selectedStatus ? "#32CD32" : "#ccc", mr: 1 }}
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        color: theme.palette.mode === "dark" ? "#fff" : "#333",
                        marginTop: 2,
                      }}
                    >
                      <strong> Status: </strong>
                      {videoStatuses?.find(
                        (status) => Object.values(status)[0] === selectedStatus
                      )
                        ? Object.keys(
                            videoStatuses?.find(
                              (status) =>
                                Object.values(status)[0] === selectedStatus
                            )
                          )[0]
                        : "Pending"}
                    </Typography>
                  </Box>
                </MotionBox>
              ) : (
                <MotionBox
                  key="edit-form"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                >
                  <TextField
                    label="Title"
                    value={editedTitle}
                    onChange={(e) => setEditedTitle(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor:
                            theme.palette.mode === "dark"
                              ? "#FFD700"
                              : "#006400",
                        },
                        "&:hover fieldset": {
                          borderColor:
                            theme.palette.mode === "dark"
                              ? "#FFD700"
                              : "#006400",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor:
                            theme.palette.mode === "dark"
                              ? "#FFD700"
                              : "#006400",
                        },
                      },
                    }}
                  />
                  <Autocomplete
                    fullWidth
                    options={fetchedCategories || []}
                    getOptionLabel={(option) => option?.name || ""}
                    value={editedTag}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Edit tag"
                        required
                        variant="outlined"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor:
                                theme.palette.mode === "dark"
                                  ? "#FFD700"
                                  : "#006400",
                            },
                            "&:hover fieldset": {
                              borderColor:
                                theme.palette.mode === "dark"
                                  ? "#FFD700"
                                  : "#006400",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor:
                                theme.palette.mode === "dark"
                                  ? "#FFD700"
                                  : "#006400",
                            },
                          },
                        }}
                      />
                    )}
                    onChange={(event, newValue) => {
                      setEditedTag(newValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id
                    }
                  />
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                    <TextField
                      label="Hadith Text"
                      value={hadithText}
                      // onChange={(e) => setHadithText(e.target.value)}
                      fullWidth
                      margin="normal"
                      placeholder="Please click keyboard icon to write hadith text"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor:
                              theme.palette.mode === "dark"
                                ? "#FFD700"
                                : "#006400",
                          },
                          "&:hover fieldset": {
                            borderColor:
                              theme.palette.mode === "dark"
                                ? "#FFD700"
                                : "#006400",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor:
                              theme.palette.mode === "dark"
                                ? "#FFD700"
                                : "#006400",
                          },
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => {
                                setHadithText("");
                              }}
                            >
                              <Close />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setKeyboardVisible((prev) => !prev);
                              }}
                            >
                              <KeyboardIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: !!editedTitle,
                      }}
                    />
                    <Button
                       variant="contained"
                       sx={{
                         textTransform: "none",
                         color: "#fff",
                         background: buttonGradient,
                         borderRadius: "50px",
                        //  width:200,
                        //  p:"1px",
                         "&:hover": {
                           background: buttonHoverGradient,
                         },
                       }}
                      onClick={async() => {
                        debugger
                        const response = await handleVideoToText(video?.videos3link);
                        setHadithText(response);
                      }}
                    >
                      Generate Text
                    </Button>
                  </Box>
                  {keyboardVisible && (
                    <Keyboard
                      onKeyPress={handleKeyPress}
                      layout={{
                        default: [
                          "ض ص ث ق ف غ ع ه خ ح ج چ",
                          "ش س ی ب ل ا ت ن م ک گ",
                          "ظ ط ز ر ڈ ژ د پ و ء",
                          "{shift} ے ۔ / {bksp}",
                        ],
                      }}
                      display={{
                        "{bksp}": "⌫",
                        "{shift}": "⇧",
                      }}
                    />
                  )}

                  <CommentsSection
                    setComment={setComment}
                    comment={comment}
                    formattedComments={formattedComments}
                    theme={theme}
                  />

                  <FormControl component="fieldset">
                    <FormLabel component="legend">Video Status</FormLabel>
                    <RadioGroup
                      value={selectedStatus}
                      onChange={handleChange}
                      row
                      sx={{ marginTop: 1 }}
                    >
                      {videoStatuses.map((status, index) => {
                        const [label, value] = Object.entries(status)[0];
                        return (
                          <FormControlLabel
                            key={index}
                            value={value}
                            control={<Radio />}
                            label={label}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                </MotionBox>
              )}
            </AnimatePresence>
          </MotionBox>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        {isEditing ? (
          <>
            <Button
              onClick={handleSave}
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#fff",
                background: buttonGradient,
                borderRadius: "50px",
                px: 4,
                "&:hover": {
                  background: buttonHoverGradient,
                },
              }}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                setIsEditing(false);
                setKeyboardVisible(false);
                setHadithText("");
              }}
              variant="outlined"
              sx={{
                textTransform: "none",
                color: theme.palette.mode === "dark" ? "#FFD700" : "#006400",
                borderColor:
                  theme.palette.mode === "dark" ? "#FFD700" : "#006400",
                borderRadius: "50px",
                px: 4,
                "&:hover": {
                  borderColor:
                    theme.palette.mode === "dark" ? "#FFA500" : "#004D00",
                  backgroundColor: alpha(theme.palette.success.main, 0.1),
                },
              }}
            >
              Cancel
            </Button>
          </>
        ) : (
          <Button
            onClick={() => {
              setKeyboardVisible(false);
              handleClose();
            }}
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#fff",
              background: buttonGradient,
              borderRadius: "50px",
              px: 4,
              "&:hover": {
                background: buttonHoverGradient,
              },
            }}
            autoFocus
          >
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
const ffmpeg = new FFmpeg({ log: true });
const processVideoToAudio = async (videoSource) => {
  // Ensure ffmpeg is loaded before proceeding
  await ffmpeg.load(); 

  // If `videoSource` is a URL, fetch it as an ArrayBuffer
  const videoData =
    typeof videoSource === 'string'
      ? new Uint8Array(await (await fetch(videoSource)).arrayBuffer())
      : new Uint8Array(await videoSource.arrayBuffer());

  // Write video to ffmpeg's virtual file system
  ffmpeg.FS('writeFile', 'input.mp4', videoData);

  // Extract audio from the video
  await ffmpeg.run('-i', 'input.mp4', '-q:a', '0', '-map', 'a', 'output.mp3');

  // Read the audio file from ffmpeg's virtual file system
  const audioData = ffmpeg.FS('readFile', 'output.mp3');
  return new Blob([audioData.buffer], { type: 'audio/mp3' }); // Return audio as a Blob
};
const handleVideoToText = async (videoSource) => {
  try {
    console.log("Processing video...");
    // Extract audio from the video

    const audioBlob = await processVideoToAudio(videoSource);
debugger
    // Create an audio element to play the extracted audio
    const audioUrl = URL.createObjectURL(audioBlob);
    const audio = new Audio(audioUrl);
    debugger

    // Wait for the audio to play and then transcribe using speech recognition
    const transcription = await new Promise((resolve, reject) => {
      audio.onplay = () => {
        console.log("Audio started playing...");
      };

      audio.onended = async () => {
        console.log("Audio ended, starting transcription...");
        try {
          // Convert the audio to text (using the Web Speech API)
          const recognition =
            new window.SpeechRecognition() ||
            new window.webkitSpeechRecognition();

          if (!recognition) {
            console.error("Speech recognition not supported in this browser.");
            return;
          }

          recognition.lang = "en-US"; // Set language
          recognition.interimResults = true; // Enable live transcriptions
          recognition.maxAlternatives = 1; // Limit to one transcription result

          recognition.onresult = (event) => {
            const transcript = event.results[event.resultIndex][0].transcript;
            console.log("Transcript:", transcript);
            resolve(transcript); // Return transcription
          };

          recognition.onerror = (error) => {
            console.error("Speech recognition error:", error);
            reject(new Error("Failed to convert speech to text"));
          };

          recognition.onend = () => {
            console.log("Speech recognition ended");
          };

          // Start speech recognition after the audio starts
          recognition.start();
        } catch (error) {
          reject(error);
        }
      };

      audio.onerror = (error) => {
        console.error("Audio playback error:", error);
        reject(new Error("Failed to play audio for transcription"));
      };

      // Play the audio
      audio.play();
    });

    console.log("Transcription:", transcription);
    debugger
    return transcription;
  } catch (error) {
    console.error("Error:", error);
  }
};

const getLatestVideoStatus = (video) => {
  if (video?.videoStatusesResponse && video?.videoStatusesResponse.length > 0) {
    const latestStatus = video?.videoStatusesResponse.sort(
      (a, b) => new Date(b.createdat) - new Date(a.createdat)
    )[0];

    return latestStatus;
  }
  return null;
};
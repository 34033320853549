import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { Tooltip } from "@mui/material";

const ToggleColorMode = ({ toggleColorMode }) => {
  const theme = useTheme();

  return (
    <Tooltip
      title={theme.palette.mode === "dark" ? "Light Mode" : "Dark Mode"}
      arrow
    >
      <IconButton
        sx={{
          color: "#fff", // Always white
          bgcolor: "rgba(255, 255, 255, 0.1)",
          "&:hover": {
            bgcolor: "rgba(255, 255, 255, 0.2)",
          },
          padding: "8px",
        }}
        onClick={toggleColorMode}
      >
        {theme.palette.mode === "dark" ? (
          <Brightness7Icon style={{ color: "#fff" }} />
        ) : (
          <Brightness4Icon style={{ color: "#fff" }} />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default ToggleColorMode;

import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { 
  Card, IconButton, Typography, Box, Grid, Paper, Popover, 
  Avatar, Tooltip, Fade, Grow, useMediaQuery, useTheme 
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import DownloadIcon from "@mui/icons-material/Download";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { SocialMediaIconsModal } from "./custom-components/SocialMediaIconsModal";
import { deleteVideo, makeVideoFavorite } from "./APIS/videos";
import { truncateString } from "./custom-components/TruncateString";
import { WarningModal } from "./custom-components/WarningModal";
import { toast } from "react-toastify";

const StyledCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const VideoOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out',
  '&:hover': {
    opacity: 1,
  },
}));

const VideoInfo = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

export const CardComponent = ({ module, isShow = false, refetch, path='video', showFavourite=true }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [showSocialMediaModal, setShowSocialMediaModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [warningModal, setWarningModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleFavoriteToggle = async () => {
    toast.promise(
      makeVideoFavorite(module.id, !module.isFavorite),
      {
        pending: 'Adding to favorites...',
        success: 'Video added to favorites!',
        error: 'Failed to add to favorites'
      }
    );
    handleClose();
    refetch();
  };

  const handleShare = () => setShowSocialMediaModal(true);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleWarningModal = (id) => {
    handleClose();
    setWarningModal(true);
  };
  const handleCloseWarningModal = () => setWarningModal(false);

  const handleDelete = async () => {
    toast.promise(
      deleteVideo(module.id),
      {
        pending: 'Deleting video...',
        success: 'Video deleted successfully!',
        error: 'Failed to delete video'
      }
    );
    handleCloseWarningModal();
    refetch();
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = module.videos3link;
    link.download = `video_${module.id}.mp4`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const hasVideoSharingPermission = permissions?.some(
    (permission) => permission?.name === "video_sharing"
  );

  return (
    <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
      <StyledCard 
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Box
          sx={{
            position: 'relative',
            paddingTop: '56.25%', // 16:9 aspect ratio
          }}
          onClick={() => navigate(`/${path}/${module.id}`)}
        >
          <Box
            component="img"
            src={module?.thumbnails3link}
            alt={module?.name}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
          <Fade in={isHovered}>
            <VideoOverlay>
              <PlayArrowIcon sx={{ fontSize: 60, color: 'white' }} />
            </VideoOverlay>
          </Fade>
        </Box>

        <VideoInfo>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Avatar alt="Channel Avatar" src="/thumbnails/Hasdees.png" />
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                {truncateString(module?.name, isMobile ? 30 : 60)}
              </Typography>
            </Box>
            <IconButton aria-label="more" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
          </Box>
          <Typography variant="body2" color="text.secondary">
            {moment(module?.createdat).fromNow()}
          </Typography>
        </VideoInfo>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Paper sx={{ p: 1, borderRadius: 2 }}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Tooltip title="Download">
                  <IconButton onClick={handleDownload}>
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              {showFavourite &&
              <Grid item>
                <Tooltip title={module?.isFavorite ? "Remove from Favorites" : "Add to Favorites"}>
                  <IconButton onClick={handleFavoriteToggle}>
                    <FavoriteIcon color={module?.isFavorite ? "error" : "inherit"} />
                  </IconButton>
                </Tooltip>
              </Grid>
              }
              {hasVideoSharingPermission && (
                <Grid item>
                  <Tooltip title="Share">
                    <IconButton onClick={handleShare}>
                      <ShareIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              {/* <Grid item>
                <Tooltip title="Edit">
                  <IconButton onClick={() => navigate(`/admin/edit/${module?.id}`)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Grid> */}
              {/* <Grid item>
                <Tooltip title="Delete">
                  <IconButton onClick={() => handleWarningModal(module.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Grid> */}
            </Grid>
          </Paper>
        </Popover>

        <SocialMediaIconsModal
          module={module}
          setShowSocialMediaModal={setShowSocialMediaModal}
          showSocialMediaModal={showSocialMediaModal}
        />
        <WarningModal
          content="Are you sure you want to delete this video?"
          openModal={warningModal}
          handleClose={handleCloseWarningModal}
          handleDelete={handleDelete}
        />
      </StyledCard>
    </Grow>
  );
};
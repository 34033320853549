import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Container,
  useTheme,
  Typography,
  IconButton,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import {
  Favorite,
  FavoriteBorder,
  Share,
  VolumeUp,
  VolumeOff,
  KeyboardArrowUp,
  KeyboardArrowDown,
} from "@mui/icons-material";
import { motion, AnimatePresence } from "framer-motion";
import moment from "moment";
import { useInfiniteQuery } from "react-query";
import { fetchVideos, makeVideoFavorite } from "./APIS/videos";
import { Element } from "react-scroll";
import { toast } from "react-toastify";
import styled from "@emotion/styled";

// Add this styled component for gradient overlay
const GradientOverlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0) 60%, rgba(0,0,0,0.9) 100%)',
  zIndex: 2,
});

export const Shorts = () => {
  const theme = useTheme();
  const [isMuted, setIsMuted] = useState(true);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const itemsPerPage = 8;
  const containerRef = useRef(null);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(
    "videos",
    ({ pageParam = 1 }) => fetchVideos(pageParam, itemsPerPage),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.videos.length === itemsPerPage) {
          return pages.length + 1;
        }
        return undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  const allVideos = data?.pages.flatMap(page => page.videos) || [];

  useEffect(() => {
    const handleScroll = (e) => {
      const container = containerRef.current;
      if (!container) return;

      const isScrollingDown = e.deltaY > 0;
      e.preventDefault();

      if (isScrollingDown) {
        if (currentVideoIndex < allVideos.length - 1) {
          setCurrentVideoIndex(prev => prev + 1);
        } else if (hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      } else if (currentVideoIndex > 0) {
        setCurrentVideoIndex(prev => prev - 1);
      }
    };

    window.addEventListener('wheel', handleScroll, { passive: false });
    return () => window.removeEventListener('wheel', handleScroll);
  }, [currentVideoIndex, allVideos.length, hasNextPage, isFetchingNextPage]);

  const handleFavoriteToggle = async (video) => {
    toast.promise(
      makeVideoFavorite(video._id, !video.isFavorite),
      {
        pending: 'Adding to favorites...',
        success: 'Video added to favorites!',
        error: 'Failed to add to favorites'
      }
    );
  };

  const handleShare = (video) => {
    const shareData = {
      title: video?.name,
      text: `Check out this video: ${video?.name}`,
      url: video.videos3link,
    };

    if (navigator.share) {
      navigator.share(shareData)
        .catch((error) => console.error('Error sharing:', error));
    } else {
      navigator.clipboard.writeText(video.videos3link)
        .then(() => toast.success('Link copied to clipboard!'))
        .catch(() => toast.error('Failed to copy link'));
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container
      ref={containerRef}
      maxWidth="sm"
      sx={{
        py: 0,
        height: "100vh",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {allVideos.length === 0 ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <Typography variant="h3" fontWeight={700}>
            No Videos to Show
          </Typography>
        </Box>
      ) : (
        <AnimatePresence mode="wait">
          <motion.div
            key={currentVideoIndex}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Element name={`video-${currentVideoIndex}`}>
              <Box
                sx={{
                  position: "relative",
                  height: "calc(100vh - 32px)",
                  width: "100%",
                  maxWidth: "450px",
                  margin: "0 auto",
                  overflow: "hidden",
                  borderRadius: "24px",
                  background: "#000",
                  boxShadow: "0 8px 32px rgba(0,0,0,0.2)",
                }}
              >
                {/* Video Element */}
                <Box
                  sx={{
                    position: 'relative',
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <video
                    src={allVideos[currentVideoIndex]?.videos3link}
                    autoPlay
                    loop
                    muted={isMuted}
                    playsInline
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                  <GradientOverlay />
                </Box>

                {/* Right Side Actions */}
                <Box
                  sx={{
                    position: "absolute",
                    right: 16,
                    bottom: "25%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2.5,
                    zIndex: 3,
                  }}
                >
                  <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                    <Box sx={{ textAlign: 'center' }}>
                      <IconButton
                        onClick={() => handleFavoriteToggle(allVideos[currentVideoIndex])}
                        sx={{
                          bgcolor: 'rgba(255,255,255,0.15)',
                          backdropFilter: 'blur(4px)',
                          '&:hover': { bgcolor: 'rgba(255,255,255,0.25)' },
                          width: 48,
                          height: 48,
                        }}
                      >
                        {allVideos[currentVideoIndex]?.isFavorite ? (
                          <Favorite sx={{ color: "#ff1744", fontSize: 26 }} />
                        ) : (
                          <FavoriteBorder sx={{ color: "white", fontSize: 26 }} />
                        )}
                      </IconButton>
                      <Typography variant="caption" sx={{ color: 'white', display: 'block', mt: 0.5 }}>
                        {allVideos[currentVideoIndex]?.likes || 0}
                      </Typography>
                    </Box>
                  </motion.div>

                  <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                    <Box sx={{ textAlign: 'center' }}>
                      <IconButton
                        onClick={() => setIsMuted(!isMuted)}
                        sx={{
                          bgcolor: 'rgba(255,255,255,0.15)',
                          backdropFilter: 'blur(4px)',
                          '&:hover': { bgcolor: 'rgba(255,255,255,0.25)' },
                          width: 48,
                          height: 48,
                        }}
                      >
                        {isMuted ? (
                          <VolumeOff sx={{ color: "white", fontSize: 26 }} />
                        ) : (
                          <VolumeUp sx={{ color: "white", fontSize: 26 }} />
                        )}
                      </IconButton>
                    </Box>
                  </motion.div>

                  <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                    <Box sx={{ textAlign: 'center' }}>
                      <IconButton
                        onClick={() => handleShare(allVideos[currentVideoIndex])}
                        sx={{
                          bgcolor: 'rgba(255,255,255,0.15)',
                          backdropFilter: 'blur(4px)',
                          '&:hover': { bgcolor: 'rgba(255,255,255,0.25)' },
                          width: 48,
                          height: 48,
                        }}
                      >
                        <Share sx={{ color: "white", fontSize: 26 }} />
                      </IconButton>
                      <Typography variant="caption" sx={{ color: 'white', display: 'block', mt: 0.5 }}>
                        Share
                      </Typography>
                    </Box>
                  </motion.div>
                </Box>

                {/* Video Info */}
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    p: 3,
                    color: "white",
                    zIndex: 3,
                  }}
                >
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      // fontWeight: 600,
                      textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                      mb: 1
                    }}
                  >
                    {allVideos[currentVideoIndex]?.name}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: 'rgba(255,255,255,0.8)',
                      textShadow: '0 1px 2px rgba(0,0,0,0.3)',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1
                    }}
                  >
                    <Box 
                      component="span" 
                      sx={{ 
                        width: 8, 
                        height: 8, 
                        borderRadius: '50%', 
                        bgcolor: '#4CAF50',
                        display: 'inline-block'
                      }} 
                    />
                    {moment(allVideos[currentVideoIndex]?.createdat).format("LL")}
                  </Typography>
                </Box>

                {/* Progress Bar */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: 16,
                    left: 16,
                    right: 16,
                    zIndex: 3,
                    display: 'flex',
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      height: 3,
                      borderRadius: 1,
                      bgcolor: 'rgba(255,255,255,0.3)',
                      flex: 1,
                    }}
                  >
                    <Box
                      sx={{
                        height: '100%',
                        width: `${((allVideos.length - currentVideoIndex) / allVideos.length) * 100}%`,
                        bgcolor: 'white',
                        borderRadius: 1,
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Element>
          </motion.div>
        </AnimatePresence>
      )}

      <AnimatePresence>
        {isFetchingNextPage && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            style={{
              position: 'fixed',
              bottom: 20,
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 1000,
            }}
          >
            <Box
              sx={{
                bgcolor: 'rgba(0, 0, 0, 0.7)',
                color: 'white',
                px: 3,
                py: 1,
                borderRadius: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <CircularProgress size={20} sx={{ color: 'white' }} />
              <Typography variant="body2">
                Loading more videos...
              </Typography>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </Container>
  );
};
import React, { useState } from "react";
import {
  Button,
  Box,
  Typography,
  Container,
  Paper,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import FavoriteIcon from '@mui/icons-material/Favorite';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00b09b',
    },
    secondary: {
      main: '#96c93d',
    },
  },
  typography: {
    fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  shape: {
    borderRadius: 12,
  },
});

const Success = () => {


  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "2rem",
        }}
      >
        <Container component="main" maxWidth="md">
          <Paper
            elevation={6}
            sx={{
              p: { xs: 3, md: 5 },
              borderRadius: "20px",
              background: 'linear-gradient(135deg, #ffffff 0%, #f0f0f0 100%)',
              boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <FavoriteIcon sx={{ fontSize: 60, color: "primary.main", mb: 2 }} />
              <Typography
                variant="h3"
                component="h1"
                sx={{
                  mb: 3,
                  fontWeight: "bold",
                  color: "primary.main",
                  textShadow: "1px 1px 2px rgba(0,0,0,0.1)",
                }}
              >
                Illuminate Minds & Hearts
              </Typography>
              <Typography
                variant="h6"
                component="p"
                sx={{
                  mb: 4,
                  color: "text.secondary",
                  lineHeight: 1.8,
                  maxWidth: "700px",
                }}
              >
                Your generous support helps us spread the light of knowledge. By donating to Islam Info, you empower countless individuals to learn and understand the beauty and truth of Islam. Join us in our mission to share knowledge and foster understanding.
              </Typography>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  py: 2,
                  background: "linear-gradient(90deg, #00b09b 0%, #96c93d 100%)",
                  color: "#fff",
                  borderRadius: "50px",
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: "18px",
                  boxShadow: "0 6px 15px rgba(0, 0, 0, 0.2)",
                  maxWidth: "400px",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-3px)",
                    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
                  },
                }}
               
              >
               Home
              </Button>
            </Box>
          </Paper>
          
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Success;
import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationButton from "../custom-components/NotificationButton";
import { NotificationModal } from "../modals/NotificationModal";
import ProcessNotificationData from "../modals/ProcessNotificationData";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import { VideoSearch } from "../custom-components/VideoSearch";
import { toast } from "react-toastify";
import ToggleColorMode from "../../ToggleColorMode";
import { Tooltip } from "@mui/material";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import { getRole } from "./Sidebar";

const dummyNotifications = [
  {
    _id: 1,
    message: "New message received",
    createdAt: "2023-10-30T10:00:00Z",
  },
  {
    _id: 2,
    message: "You have a meeting at 2 PM",
    createdAt: "2023-10-29T15:30:00Z",
  },
  {
    _id: 3,
    message: "Reminder: Pay your bills",
    createdAt: "2023-10-28T09:45:00Z",
  },
  {
    _id: 4,
    message: "New friend request from John",
    createdAt: "2023-10-27T18:20:00Z",
  },
  {
    _id: 5,
    message: "Congratulations on your promotion!",
    createdAt: "2023-10-26T11:15:00Z",
  },
];
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // ...(open && {
  //   marginLeft: drawerWidth,
  //   width: `calc(100% - ${drawerWidth}px)`,
  //   transition: theme.transitions.create(['width', 'margin'], {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  // }),
}));
export const Header = ({ handleDrawer, open, toggleColorMode }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const role = getRole();

  const [openNotifyModal, setOpenNotifyModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleCloseNotifyModal = () => {
    setOpenNotifyModal(false);
  };

  useEffect(() => {
    ProcessNotificationData(dummyNotifications);
  }, []);

  const UnreadMessagesCount = () => {
    const storedNotificationsStr = localStorage.getItem("messages");
    const storedNotifications = storedNotificationsStr
      ? JSON.parse(storedNotificationsStr)
      : [];

    if (storedNotifications) {
      const content = [...storedNotifications].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      const readNotificationsStr = localStorage.getItem("readNotifications");
      const readNotifications = readNotificationsStr
        ? JSON.parse(readNotificationsStr)
        : [];
      const unreadMessages =
        content.length > 0 ? content.length - readNotifications.length : 2;

      return unreadMessages;
    }
    return 0;
  };

  const unreadNotifications = UnreadMessagesCount();
  const showSearchAndAudioIcons = ![
    "superadmin",
    "teacher",
    "student",
    "dataentry",
  ].includes(role?.name.toLowerCase());

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{ backgroundColor: "green", boxShadow: "10px" }}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            sx={{
              marginRight: 5,
              color: "#fff",
              marginLeft: 1,
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              width: "80px",
              height: "80px",
            }}
          >
            <img
              src="/logo.png"
              alt="logo"
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </Box>

          {/* <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ color: "#009900" }}
          >
            IslamInfo Tube
          </Typography> */}
          {showSearchAndAudioIcons && (
            <>
              <VideoSearch />
              <IconButton
                size="small"
                aria-label="account of current user"
                aria-haspopup="true"
                sx={{
                  bgcolor: "rgba(255, 255, 255, 0.1)",
                  "&:hover": {
                    bgcolor: "rgba(255, 255, 255, 0.2)",
                  },
                  padding: "8px",
                }}
                // onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                <KeyboardVoiceIcon sx={{ color: "#fff", m: "5px" }} />
              </IconButton>
            </>
          )}

          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title="Donate" arrow>
            <IconButton
              color="primary"
              onClick={() => navigate("/donation")}
              sx={{
                bgcolor: "rgba(255, 255, 255, 0.1)",
                "&:hover": {
                  bgcolor: "rgba(255, 255, 255, 0.2)",
                },
                padding: "8px",
              }}
            >
              <VolunteerActivismIcon fontSize="medium" sx={{ color: "#fff" }} />
            </IconButton>
          </Tooltip>
          <Box sx={{ display: { xs: "none", md: "flex" }, gap: 2 }}>
            <ToggleColorMode
              toggleColorMode={toggleColorMode}
              sx={{ color: "#fff" }}
            />
            <NotificationButton
              setOpenNotifyModal={setOpenNotifyModal}
              unReadCount={unreadNotifications}
            />
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <AccountCircle sx={{ color: "#fff" }} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              onClick={() => setAnchorEl(null)}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={() => setAnchorEl(null)}>
                <Avatar /> Profile
              </MenuItem>
              <MenuItem onClick={() => setAnchorEl(null)}>
                <Avatar /> My account
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => setAnchorEl(null)}>
                <ListItemIcon>
                  <PersonAdd fontSize="small" />
                </ListItemIcon>
                Add another account
              </MenuItem>
              <MenuItem onClick={() => setAnchorEl(null)}>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem>
              <MenuItem
                onClick={() => {
                  toast.success("logged Out");
                  localStorage.removeItem("authToken");
                  localStorage.removeItem("user");
                  navigate("/");
                  setAnchorEl(null);
                }}
              >
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <NotificationModal
        openModal={openNotifyModal}
        handleClose={handleCloseNotifyModal}
        refresh={() => setRefresh(!refresh)}
      />
      {/* <Box component="main" sx={{ flexGrow: 1, p: 3 }}></Box> */}
    </Box>
  );
};
